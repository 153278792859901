import Typewriter from 'typewriter-effect';

const ComingSoon = (): JSX.Element => (
  <section className='flex h-dvh w-dvw items-center justify-center bg-[#F7E7D0] font-patrick-hand-sc text-4xl text-[#7C420B] md:text-5xl'>
    <Typewriter
      options={{
        strings: ['COMING SOON', 'SEGERA HADIR', '間もなく'],
        autoStart: true,
        loop: true
      }}
    />
  </section>
);

export default ComingSoon;
