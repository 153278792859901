import './app.css';
import {BrowserRouter} from 'react-router-dom';
import Router from './router';
import {StrictMode} from 'react';

const App = (): JSX.Element => (
  <StrictMode>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </StrictMode>
);

export default App;
