import {Navigate, Route, Routes} from 'react-router-dom';
import ComingSoon from './../pages/coming-soon';

const Router = (): JSX.Element => (
  <Routes>
    <Route path='/' element={<ComingSoon />} />
    <Route path='*' element={<Navigate to='/' />} />
  </Routes>
);

export default Router;
